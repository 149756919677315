import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import Layout from '../../templates/layout';
import OrdersList from '../../components/user/orders/list';
import BasePageContent from '../../components/utils/BasePageContent';
import ScheduledOrders from '../../components/orderRenewals/ScheduledOrders';

const TabPanel = ({ children, tabIdx, index, ...other }) => (
  <Box
    role='tabpanel'
    hidden={tabIdx !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {tabIdx === index && <Box sx={{ py: 4 }}>{children}</Box>}
  </Box>
);

const OrdersPage = () => {
  const [tabIdx, setTabIdx] = useState(0);
  const handleChange = (_, tabIdx) => setTabIdx(tabIdx);

  return (
    <Layout>
      <BasePageContent>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={tabIdx}
              onChange={handleChange}
              aria-label='Zakładki płatności'
            >
              <Tab label='Utworzone' id='tab-0' aria-controls='tabpanel-0' />
              <Tab label='Planowane' id='tab-1' aria-controls='tabpanel-1' />
            </Tabs>
          </Box>
          <TabPanel tabIdx={tabIdx} index={0}>
            <OrdersList />
          </TabPanel>
          <TabPanel tabIdx={tabIdx} index={1}>
            <ScheduledOrders />
          </TabPanel>
        </Box>
      </BasePageContent>
    </Layout>
  );
};

export const Head = () => <title>Płatności | Zapłatomat</title>;

export default OrdersPage;
