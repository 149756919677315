import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ScheduleAccordion from './ScheduleAccordion';
import BaseLoader from '../utils/BaseLoader';
import {Alert} from "@mui/material";

const mapStateToProps = state => ({
  schedule: state.getScheduleForAllRenewals,
});

const ScheduledOrders = ({ actions, schedule }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    actions.getScheduleForAllRenewals();
  }, [actions]);

  useEffect(() => {
    if (schedule) setLoading(false);
  }, [schedule]);

  return (
    <>
      <BaseLoader loading={loading} />
      {!!Object.keys(schedule || {}).length ? <ScheduleAccordion schedule={schedule} />
      : <Alert severity='info'>Brak zaplanowanych zleceń do wyświetlenia</Alert> }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrders);
